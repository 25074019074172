import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import App from "./pages/index";

import "./index.css";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

function NotFound() {
  return (
    <div>
      <h1>404 - Not Found</h1>
      <p>The page you're looking for does not exist.</p>
    </div>
  );
}

root.render(
  <>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/home" element={<App />} />
        <Route path="/test" element={<h1>working</h1>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  </>
);
