import { Heading, Box, Text, Container, Grid } from "theme-ui";
import React, { useRef } from "react";
import {
  Label,
  Input,
  Select,
  Textarea,
  Radio,
  Checkbox,
  Slider,
  Flex,
  Button,
} from "theme-ui";

import emailjs from "@emailjs/browser";

import ArrowOdd from "assets/arrow-odd.png";
import ArrowEven from "assets/arrow-even.png";
import Image from "components/image";
import BlockTitle from "components/block-title";

const workflowData = [
  {
    title: "Set disbursement Instructions",
    text: "Get your blood tests delivered at home collect a sample from the your blood tests.",
  },
  {
    title: "Assembly retrieves funds from your account",
    text: "Get your blood tests delivered at home collect a sample from the your blood tests.",
  },
  {
    title: "Assembly initiates disbursement",
    text: "Get your blood tests delivered at home collect a sample from the your blood tests.",
  },
  {
    title: "Customer receives funds payment",
    text: "Get your blood tests delivered at home collect a sample from the your blood tests.",
  },
];

const Contact = () => {
  const form = useRef();

  return (
    <Box as="section" id="contact" sx={styles.workflow}>
      <BlockTitle
        sx={styles.workflow.blockTitle}
        tagline="Fast & Free Quote"
        heading="Get a free consultation with a construction expert about your unique needs."
      />
      <Container
        style={{
          display: "flex",
          gap: window.innerWidth < 768 ? "2rem" : "5rem",
          flexDirection: window.innerWidth < 768 ? "column" : "row",
        }}
      >
        <Image
          style={{
            width: window.innerWidth < 768 ? "100%" : "70%",
            height: "50%",
            alignSelf: "center",
            borderRadius: "8px",
          }}
          src="https://images.unsplash.com/photo-1626885930974-4b69aa21bbf9?auto=format&fit=crop&q=80&w=1646&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="construction image"
        />

        <form
          ref={form}
          style={{
            width: window.innerWidth < 768 ? "100%" : "50%",
          }}
          // action="https://formsubmit.co/el/habeti"
          onSubmit={(e) => {
            e.preventDefault();
            emailjs
              .sendForm(
                "service_2octgch",
                "template_soeiwbw",
                form.current,
                "Hh4ueA5IiHBtEFOB1"
              )
              .then(
                (result) => {
                  alert(
                    "We have received your query, and we will contact you shortly"
                  );
                },
                (error) => {
                  console.log(error.text);
                }
              );
          }}
        >
          <Label htmlFor="name">Name</Label>
          <Input
            style={{ border: "1px solid gray" }}
            name="name"
            id="name"
            mb={3}
          />
          <Label htmlFor="Email">Email</Label>
          <Input
            style={{ border: "1px solid gray" }}
            type="email"
            name="email"
            id="email"
            mb={3}
          />
          <Label htmlFor="phone">Phone</Label>
          <Input
            style={{ border: "1px solid gray" }}
            name="phone"
            id="phone"
            rows={6}
            mb={3}
          />
          <Label htmlFor="comment">Comment or Message</Label>
          <Textarea
            name="comment"
            Name="comment"
            id="comment"
            rows={6}
            mb={3}
          />

          <Button style={{ float: "right" }}>Submit</Button>
        </form>
      </Container>
    </Box>
  );
};

export default Contact;

const styles = {
  workflow: {
    background: "linear-gradient(180deg, #EBF9FF 0%, #F6F6FF 100%)",
    position: "relative",
    pt: "100px",
    pb: "100px",
    "@media screen and (max-width: 1366px)": {
      pb: "100px",
    },
    "@media screen and (max-width: 992px)": {
      pb: "60px",
      paddingTop: "60px",
    },
    blockTitle: {
      marginBottom: [30, null, 55],
      textAlign: "center",
    },
    grid: {
      pt: 55,
      "@media screen and (max-width: 1366px)": {
        pt: 0,
      },
      "@media screen and (max-width: 1200px)": {
        gridGap: "50px 30px",
      },
      "@media screen and (max-width: 992px)": {
        gridTemplateColumns: "repeat(2,1fr)",
        gridGap: "50px 50px",
      },
      "@media screen and (max-width: 480px)": {
        gridTemplateColumns: "repeat(1,1fr)",
        gridGap: "35px 0",
      },
    },
    card: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      "&::before": {
        position: "absolute",
        content: '""',
        top: 0,
        left: 75,
        backgroundRepeat: `no-repeat`,
        backgroundPosition: "center center",
        width: 215,
        height: 60,
        "@media screen and (max-width: 1366px)": {
          left: 75,
        },
        "@media screen and (max-width:1200px)": {
          display: "none",
        },
      },
      "&:nth-of-type(2n-1)::before": {
        backgroundImage: `url(${ArrowOdd})`,
      },
      "&:nth-of-type(2n)::before": {
        backgroundImage: `url(${ArrowEven})`,
        top: 17,
      },
      "&:last-child::before": {
        display: "none",
      },
    },

    iconBox: {
      width: "70px",
      height: "70px",
      borderRadius: "40%",
      backgroundColor: "white",
      display: "flex",
      alignItems: "center",
      mb: 30,
      fontSize: 30,
      fontWeight: 700,
      justifyContent: "center",
      color: "black",
      boxShadow: "0px 14px 20px rgba(76, 119, 171, 0.1)",
      "@media screen and (max-width: 560px)": {
        width: "50px",
        height: "50px",
        fontSize: 24,
        borderRadius: 20,
        mb: 25,
      },
      "@media screen and (max-width: 480px)": {
        mb: 20,
      },
    },
    wrapper: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      mt: "-5px",
      title: {
        fontSize: 22,
        color: "black",
        lineHeight: 1.55,
        fontWeight: 500,
        mb: "15px",
        "@media screen and (max-width: 1200px)": {
          fontSize: 20,
          lineHeight: 1.4,
        },
        "@media screen and (max-width: 480px)": {
          fontSize: "18px",
          lineHeight: 1.45,
          mb: "10px",
        },
      },

      subTitle: {
        fontSize: "15px",
        fontWeight: 400,
        lineHeight: "1.73",
        color: "#343D48",
        "@media screen and (max-width: 480px)": {
          fontSize: "15px",
          lineHeight: "1.85",
        },
      },
    },
  },
};
