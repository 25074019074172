import React from "react";
import { ThemeProvider } from "theme-ui";

import theme from "theme";
import SEO from "components/seo";
import Layout from "components/layout";
import Modal from "components/modal";
import Banner from "sections/banner";
import CtaOne from "sections/cta-one";
import FAQ from "sections/faq";
import CtaTwo from "sections/cta-two";
import WorkFlow from "sections/workflow";
// import Contact from "sections/Contact";
import Testimonials from "sections/testimonials";
import CtaThree from "sections/cta-three";
import Featured from "sections/featured";
import Services from "sections/services";
import Contact from "sections/contact";

export default function IndexPage() {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO
          description="At Apex Constructions, we bring your construction projects to life.
              we offer a wide range of services to meet your construction needs.
              Whether you're embarking on a small-scale residential project or a large
              commercial development, we're here to provide top-tier solutions that ensure your project's success.
              "
          title="Best Construction Services"
        />
        <Banner />
        <Services />
        <Contact />
        {/* <FavoriteCourse /> */}
        <CtaThree />
        <WorkFlow />
        <Testimonials />

        {/* <CtaTwo /> */}
        <Featured />
        <FAQ />
        {/* <CtaOne /> */}
        <Modal />
      </Layout>
    </ThemeProvider>
  );
}
