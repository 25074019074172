import React from "react";
import { BaseAccordion } from "./base-accordion";
import { FaPlus, FaMinus } from "react-icons/fa";
import {
  AccordionButton,
  AccordionItem,
  AccordionContents,
  single,
  preventClose,
  combineReducers,
} from "./shared";

export default function Accordion({ items, ...props }) {
  const openIcon = (
    <FaMinus
      style={{
        background: "lightgreen",
        color: "white",
        transform: "scale(1.2)",
        clipPath: "circle()",
        marginRight: "0.5rem",
        padding: "2px",
      }}
    />
  );
  const closeIcon = (
    <FaPlus
      style={{
        background: "lightgreen",
        color: "white",
        transform: "scale(1.2)",
        clipPath: "circle()",
        marginRight: "0.5rem",
        padding: "2px",
      }}
    />
  );
  return (
    <BaseAccordion
      stateReducer={combineReducers(single, preventClose)}
      {...props}
    >
      {({ openIndexes, handleItemClick }) => (
        <>
          {items.map((item, index) => (
            <AccordionItem
              key={item.title}
              isOpen={openIndexes.includes(index)}
            >
              <AccordionButton onClick={() => handleItemClick(index)}>
                <span>
                  {openIndexes.includes(index) ? openIcon : closeIcon}
                </span>
                {item.title}
              </AccordionButton>
              <AccordionContents isOpen={openIndexes.includes(index)}>
                {item.contents}
              </AccordionContents>
            </AccordionItem>
          ))}
        </>
      )}
    </BaseAccordion>
  );
}
