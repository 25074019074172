import React from "react";
import { Box, Container } from "theme-ui";
import Accordion from "components/accordion/accordion";
import BlockTitle from "components/block-title";

const accordionData = [
  {
    isExpanded: false,
    title: "WHAT TYPES OF ESTIMATES DO WE PROVIDE?    ",
    contents: (
      <div>
        We are specialists in residential, commercial & industrial projects
        performing estimating services for new construction, remodeling,
        renovation, skyscrapers, multifamily, retail, wastewater treatment
        plants, cogeneration plants, warehouses, and highways & Bridges. Apex
        Services & Solutions serves General Contractors, Subcontractors,
        Architects, Real estate developers, Owners, Lenders, Appraisers, and
        more.
      </div>
    ),
  },
  {
    isExpanded: true,
    title: "WHICH TRADES ARE WE DEALING IN?    ",
    contents: (
      <div>
        We deal with all CSI divisions and trades of construction. We provide
        Civil, Structural, Drywall, Framing, Mechanical, Electrical, Plumbing,
        Roofing, Flooring, Structural & Misc. Steel, Thermal and moisture
        protection, Fire suppression, Sitework and Lumber takeoffs, and detailed
        estimates
      </div>
    ),
  },
  {
    isExpanded: false,
    title: "WOULD YOU EVER PROVIDE THE SAME ESTIMATE TO OTHER CLIENTS?    ",
    contents: (
      <div>
        NO and Never! As CEO of Apex Services & Solutions, I ensure high
        standards and good business ethics. We strongly believe it’s unethical
        and prohibited to breach your information and disrupt long term business
        relationships. This is something we never engage in. We provide
        consultancy to our clients about how they can be competitive, win bids
        but also increase their bid-hit ratio.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: "HOW MUCH ACCURATE WE ARE IN OUR ESTIMATION?    ",
    contents: (
      <div>
        Our estimators are experts literally, they are engineers, Trade
        specialists, Field Superintendents, and project managers and for
        pricing, we use zip-code based pricing that brings into account even
        inflation, shortages in material, import/export tariffs and natural
        disaster as well.
      </div>
    ),
  },
];

const FAQ = () => {
  return (
    <Box
      as="section"
      id="faq"
      // styles={{ marginTop: "5rem !important" }}

      sx={styles.accordion}
    >
      <Container sx={styles.accordion.container}>
        <BlockTitle
          sx={styles.accordion.blockTitle}
          tagline="Frequent Question"
          heading="Do you have any question?"
        />
        <Accordion items={accordionData} />
      </Container>
    </Box>
  );
};
export default FAQ;

const styles = {
  accordion: {
    marginTop: "5rem",
    container: {
      maxWidth: "900px",
    },
    blockTitle: {
      marginBottom: [25, null, null, 65],
      textAlign: "center",
    },
  },
};
