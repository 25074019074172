// import dynamic from "next/dynamic";
import { jsx, Box, Container } from "theme-ui";
import { Swiper, SwiperSlide } from "swiper/react";
import SectionHeading from "components/section-heading";
// const Testimonial = dynamic(() => import("components/cards/testimonial"));
import Testimonial from "components/cards/testimonial";
import avatar1 from "assets/testimonials/1.png";
import avatar2 from "assets/testimonials/2.png";
import avatar3 from "assets/testimonials/3.png";
import avatar4 from "assets/testimonials/4.png";
import avatar5 from "assets/testimonials/5.png";
import avatar6 from "assets/testimonials/6.png";
import avatar7 from "assets/testimonials/7.png";
import avatar8 from "assets/testimonials/8.png";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

const data = [
  [
    {
      id: 1,
      avatar: avatar1,
      name: "James Mitchell",
      username: "@jamesmitchell",
      text: `I can't thank Apex Services enough. Their 3D BIM modeling expertise was a game-changer for our project. They turned our vision into reality, making the entire process seamless and efficient. Working with them was a breath of fresh air, and we'll definitely partner with them on future projects.`,
    },
    {
      id: 2,
      avatar: avatar2,
      name: "Sarah Walker",
      username: "@sarahwalker",
      text: `Apex Services, you're amazing! Your 4D and 5D BIM services made our complex project a breeze. The integration of time and cost information saved us not just money, but headaches too. Thanks to you, our project was on track and on budget. We couldn't be happier!`,
    },
  ],
  [
    {
      id: 3,
      avatar: avatar3,
      name: "David Evans",
      username: "@davidevans",
      text: `Big kudos to Apex Services for their meticulous Bill of Quantity. They provided us with accurate material takeoffs and cost estimates, which streamlined our project and ensured cost-efficiency. They've earned my trust, and I'll gladly recommend their services.`,
    },
    {
      id: 4,
      avatar: avatar4,
      name: "Emily Turner",
      username: "@emilyturner",
      text: `I can't thank Apex Services enough for their clash detection expertise. They spotted issues before they became problems on-site. They are true professionals, and their attention to detail was a game-changer for our project. If you want a smooth construction process, they're the team to trust!`,
    },
  ],
  [
    {
      id: 5,
      avatar: avatar5,
      name: "John Anderson",
      username: "@johnanderson",
      text: `I'd like to express my gratitude to Apex Services. Their construction expertise and precise time and cost estimates made our project a resounding success. They don't just build; they create success. Their commitment to excellence is commendable.`,
    },
    {
      id: 6,
      avatar: avatar6,
      name: "Olivia Parker",
      username: "@oliviaparker",
      text: `Apex Services exceeded our expectations. Their accuracy and efficiency in our construction project were unmatched. It was a pleasure working with them. I'm looking forward to future projects together. I wholeheartedly recommend their services!`,
    },
  ],
  [
    {
      id: 7,
      avatar: avatar7,
      name: "Robert Smith",
      username: "@robertsmith",
      text: `Working with Apex Services was a fantastic experience. Their precision and commitment were evident at every stage of our project. They provided top-tier solutions, and we couldn't be happier with the results. They're a trusted partner in building success.`,
    },
    {
      id: 8,
      avatar: avatar8,
      name: "Alice Davis",
      username: "@alicedavis",
      text: `Our project was a great success, thanks to Apex Services. They made construction a smooth and efficient process. We felt like we were in expert hands throughout the project. If you want a hassle-free construction experience, Apex Services is the way to go.`,
    },
  ],
  [
    {
      id: 9,
      avatar: avatar1,
      name: "Daniel White",
      username: "@danielwhite",
      text: `We are grateful to Apex Services for their commitment to excellence. Their precise material takeoffs and cost estimates saved us time and resources, making our project a success. We're confident in recommending their services to others.`,
    },
    {
      id: 10,
      avatar: avatar2,
      name: "Linda Green",
      username: "@lindagreen",
      text: `Our experience with Apex Services was exceptional. Their attention to detail and clash detection expertise ensured a smooth construction process. They're the team to trust when you want your project to go off without a hitch!`,
    },
  ],
  [
    {
      id: 11,
      avatar: avatar3,
      name: "Michael Turner",
      username: "@michaelturner",
      text: `Thank you, Apex Services, for your swift and professional service. Your expertise in 3D BIM modeling and cost estimation made our project efficient and cost-effective. We're pleased with the outcome and will consider you for future projects.`,
    },
    {
      id: 12,
      avatar: avatar4,
      name: "Amanda Parker",
      username: "@amandaparker",
      text: `Our recent project was a success, all thanks to Apex Services. Their dedication and attention to detail ensured our construction dreams became reality. We're delighted with the results and would gladly work with them again.`,
    },
  ],
];

const Testimonials = () => {
  const options = {
    spaceBetween: 20,
    loop: true,
    grabCursor: true,
    centeredSlides: true,
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      640: {
        slidesPerView: 2,
      },
      1366: {
        slidesPerView: 3,
      },
      1600: {
        slidesPerView: 4,
      },
    },
  };
  return (
    <section id="testimonials" sx={styles.section}>
      <Container>
        <SectionHeading
          sx={styles.heading}
          // style={{}}
          title="What client say about us"
          description="Customer testimonial"
        />
      </Container>
      <Swiper autoplay sx={styles.carousel} {...options}>
        {data.map((item, index) => (
          <SwiperSlide key={index}>
            {item.map((slide) => (
              <Testimonial key={slide.id} data={slide} />
            ))}
          </SwiperSlide>
        ))}
      </Swiper>
      {/* <Box sx={styles.testimonials}></Box> */}
    </section>
  );
};

export default Testimonials;

const styles = {
  section: {
    backgroundColor: "#FFFCF7",
    pt: [10, null, null, 9, 10, 11, 11],
    pb: [7, null, null, 7, 7, 9, 9],
  },
  heading: {
    h2: {
      fontSize: [6, null, null, 8],
      textAlign: "center",
      marginTop: "5rem",
    },
    p: {
      textAlign: "center",
      color: "#858B91",
      fontSize: 3,
      m: ["10px auto", null, null, "0 auto"],
    },
  },
  carousel: {
    "&.swiper-container": {
      pb: [1],
      pl: [6, null, null, 0],
      pr: [6, null, null, 0],
    },
  },
};
