import React, { useState } from "react";
import { Box, Container, Button } from "theme-ui";
import { keyframes } from "@emotion/core";
import BlockTitle from "components/block-title";
import Image from "components/image";
import {
  AiFillBuild,
  AiFillPieChart,
  AiFillDollarCircle,
} from "react-icons/ai";
import { GiSwordClash } from "react-icons/gi";

import { FaBriefcase } from "react-icons/fa";

import dotPattern from "assets/dot-pattern.svg";

const Featured = () => {
  const [tab, setTab] = useState({
    active: "clash",
  });

  const handleTab = (tab) => {
    if (tab === "clash") {
      setTab({
        ...tab,
        active: "clash",
      });
    }
    if (tab === "bim") {
      setTab({
        ...tab,
        active: "bim",
      });
    }
    if (tab === "estimation") {
      setTab({
        ...tab,
        active: "estimation",
      });
    }
    if (tab === "takeoff") {
      setTab({
        ...tab,
        active: "takeoff",
      });
    }
  };
  return (
    <Box as="section" id="work" sx={styles.featured}>
      <Container sx={styles.container}>
        <BlockTitle
          sx={{
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
          tagline="some of past work"
          heading="Previous Projects"
        />
        <Box sx={styles.tabButtonTopWrapper}>
          <Box sx={styles.tabButtonWrapper}>
            <Button
              onClick={() => handleTab("clash")}
              className={`${tab.active === "clash" ? "active" : ""}`}
            >
              <GiSwordClash />
              Clash Detection
            </Button>
            <Button
              onClick={() => handleTab("bim")}
              className={`${tab.active === "bim" ? "active" : ""}`}
            >
              <AiFillBuild />
              3D BIM
            </Button>
            <Button
              onClick={() => handleTab("estimation")}
              className={`${tab.active === "estimation" ? "active" : ""}`}
            >
              <AiFillDollarCircle />
              Cost Estimation
            </Button>
            <Button
              onClick={() => handleTab("takeoff")}
              className={`${tab.active === "takeoff" ? "active" : ""}`}
            >
              <FaBriefcase />
              Material Takeoffs
            </Button>
          </Box>
        </Box>
        <Box sx={styles.tabContent}>
          {tab.active === "clash" && (
            <Image
              style={{
                margin: "auto",
                display: "flex",
              }}
              src={
                "https://drawable.com.au/wp-content/uploads/2019/01/Revit-BIM-1.jpg"
              }
              alt="tab image"
              className="tabImage"
            />
          )}
          {tab.active === "bim" && (
            <Image
              style={{
                margin: "auto",
                display: "flex",
              }}
              src={
                "https://www.bimcommunity.com/files/images/userlib/BIM-3D-1.jpg"
              }
              alt="tab image"
              className="tabImage"
            />
          )}
          {tab.active === "estimation" && (
            <Image
              style={{
                margin: "auto",
                display: "flex",
              }}
              src={
                "https://www.onlinecivilforum.com/site/wp-content/uploads/2018/04/Cost-Estimation-RCC-Building-Excel-Sheet.png"
              }
              alt="tab image"
              className="tabImage"
            />
          )}
          {tab.active === "takeoff" && (
            <Image
              style={{
                margin: "auto",
                display: "flex",
              }}
              src={
                "https://fiverr-res.cloudinary.com/images/q_auto,f_auto/gigs3/224017432/original/758e55bcb9abfce47ab2274655e2535abe588875/do-material-take-off-for-site-package.png"
              }
              alt="tab image"
              className="tabImage"
            />
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default Featured;
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const styles = {
  featured: {
    pt: ["80px", null, null, null, "80px", null, "100px"],
    backgroundColor: "#F9FAFC",
  },
  container: {
    position: "relative",
    // top: "150px",
    // mt: "-150px",
  },
  tabButtonTopWrapper: {
    overflowY: ["hidden", null, null, null, null, "inherit"],
    overflowX: ["auto", null, null, null, null, "inherit"],
  },
  tabButtonWrapper: {
    width: ["700px", null, null, null, null, "100%"],
    mx: ["auto", null, null, null, null, "0"],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderBottom: "1px solid rgba(1,7,13,.1)",
    mb: "35px",
    button: {
      display: "flex",
      alignItems: "center",
      pb: ["15px", null, null, null, "35px"],
      px: ["15px", null, null, null, "30px"],
      flexShrink: "0",
      border: 0,
      backgroundColor: "rgba(0,0,0,0)",
      color: "#0F2137",
      fontSize: ["14px", null, null, null, "18px"],
      fontWeight: 500,
      lineHeight: 1,
      position: "relative",
      transition: "all 500ms ease",
      svg: {
        fontSize: ["18px", null, null, null, "30px"],
        color: "#ADBDD0",
        opacity: 0.7,
        mr: "15px",
        transition: "all 500ms ease",
      },
      "&:hover, &.active": {
        backgroundColor: "rgba(0,0,0,0)",
        color: "#0F2137",
        svg: {
          color: "#0F2137",
          opacity: 1,
        },
        "&::before": {
          transform: "scale(1,1)",
        },
      },
      "&::before": {
        content: "''",
        position: "absolute",
        bottom: "-2px",
        backgroundColor: "#0F2137",
        left: 0,
        width: "100%",
        height: "3px",
        transform: "scale(0,1)",
        transition: "transform 500ms ease",
      },
    },
  },
  tabContent: {
    minHeight: ["190px", null, "300px", "385px", null, "600px"],
    position: "relative",
    "&::before": {
      content: "''",
      width: "302px",
      height: "347px",
      backgroundImage: `url(${dotPattern})`,
      position: "absolute",
      bottom: "-30px",
      right: "-40px",
      display: ["none", null, null, null, null, "block"],
    },
    ".tabImage": {
      position: "relative",
      animation: `${fadeIn} 0.8s linear`,
    },
  },
};
