import React from "react";
import { Box, Container, Grid } from "theme-ui";
import BlockTitle from "components/block-title";
import ServiceCard from "components/cards/services";
import { FcBusinesswoman } from "react-icons/fc";

const SERVICES_DATA = [
  {
    image: "https://static.thenounproject.com/png/216058-200.png",
    text: "Transform your construction project with detailed 3D modeling for enhanced visualization and coordination.",
    heading: "3D BIM Modeling",
    path: "#",
  },
  {
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJ5EBKjq3w4T0Gbfwmhgk4auWS7-EKT28rjdJzZ6BE1OOkYkQiMz0rDvdNFe1arr042QE&usqp=CAU",
    text: "Gain transparency and control with our comprehensive Bill of Quantity services.",
    heading: "Detailed BOQ",
    path: "#",
  },
  {
    image: "https://static.thenounproject.com/png/4958190-200.png",
    text: "Integrate time and cost data into your 3D models for better project management and budget control.",
    heading: "4D and 5D BIM Services",
    path: "#",
  },
  {
    image:
      "https://w7.pngwing.com/pngs/951/327/png-transparent-calculating-cost-illustration.png",
    text: "Accurately calculate materials and resources to streamline procurement and project planning.",
    heading: "Material Takeoffs",
    path: "#",
  },
  {
    image:
      "https://boltestimating.com/wp-content/uploads/2021/04/output-onlinepngtools_-_2021-04-18T005809.026-removebg-preview.png",
    text: "Rely on our expertise for precise projections to ensure on-time and on-budget project delivery.",
    heading: "Time and Cost Estimates",
    path: "#",
  },
  {
    image:
      "https://www.tejjy.com/wp-content/uploads/2023/03/BIM-Clash-Detection.png",
    text: "Identify and resolve design clashes early to prevent costly on-site disruptions.",
    heading: "Clash Detection",
    path: "#",
  },
];

const Services = () => {
  return (
    <Box as="section" id="services" sx={styles.services}>
      <Container>
        <BlockTitle
          sx={{
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
          tagline="What the features of product"
          heading="Features are highlighted here"
        />
        <Grid
          sx={styles.grid}
          style={{ marginTop: "3rem", marginBottom: "2rem" }}
        >
          {SERVICES_DATA.map(({ image, text, heading, path }, index) => (
            <ServiceCard
              image={image}
              text={text}
              heading={heading}
              path={path}
              key={index}
            />
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Services;

const styles = {
  services: {
    pt: ["80px", null, null, null, "80px", null, "100px"],
  },
  grid: {
    gridGap: "30px",
    gridTemplateColumns: ["1fr", null, null, "1fr 1fr", null, "1fr 1fr 1fr"],
  },
};
