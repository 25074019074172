import { jsx } from "theme-ui";
import React, { useCallback } from "react";

import { Waypoint } from "react-waypoint";

import Header from "./header/header";
import Footer from "./footer/footer";

export default function Layout({ children }) {
  const isSticky = "isSticky";
  const dispatch = () => {};
  const setSticky = useCallback(
    () => dispatch({ type: "SET_STICKY" }),
    [dispatch]
  );
  const removeSticky = useCallback(
    () => dispatch({ type: "REMOVE_STICKY" }),
    [dispatch]
  );

  const onWaypointPositionChange = ({ currentPosition }) => {
    if (currentPosition === "above") {
      setSticky();
    }
    if (currentPosition === "below") {
      removeSticky();
    }
  };

  return (
    <React.Fragment>
      <Header className={`${isSticky ? "sticky" : "unSticky"}`} />

      <Waypoint
        onEnter={removeSticky}
        // onLeave={setSticky}
        onPositionChange={onWaypointPositionChange}
      />

      <main
        sx={{
          variant: "layout.main",
        }}
      >
        {children}
      </main>
      {/* <Footer /> */}
    </React.Fragment>
  );
}
