export default [
  {
    path: "banner",
    label: "Home",
  },
  {
    path: "services",
    label: "Services",
  },
  {
    path: "projects",
    label: "Projects",
  },
  {
    path: "process",
    label: "Process",
  },
  {
    path: "testimonials",
    label: "Testimonials",
  },
  {
    path: "work",
    label: "Previous Work",
  },
  {
    path: "faq",
    label: "Faq",
  },
];
